<template>
  <div class="global-footer">
    <div class="footer-head clearfix">
      <div class="container">
        <div class="pull-right">
          <a href="/privacy" target="_blank">隱私協議</a>
          <a :href="pageLink" target="_blank">
            <img src="../../../static/images/home/facebook.png" alt="">
            <span>Facebook</span>
          </a>
          <div class="fb-like-btn">
            <div class="fb-customerchat" v-if="language === 'jp'"
                 attribution="setup_tool"
                 page_id="104557935331848"
                 theme_color="#f56368"
                 logged_in_greeting="問題がある？"
                 logged_out_greeting="問題がある？"></div>
            <div class="fb-customerchat" v-else-if="language === 'en'"
                 attribution="setup_tool"
                 page_id="104557935331848"
                 theme_color="#f56368"></div>
            <div class="fb-customerchat"
                 logged_in_greeting="hi，有任何問題可以私訊我哦！"
                 logged_out_greeting="hi，有任何問題可以私訊我哦！"
                 page_id="104557935331848" v-else></div>
            <div id="fb-root"></div>
            <div class="fb-like" v-if="language === 'en'"
                 data-href="https://www.facebook.com/iPastBookBot"
                 data-layout="button_count"
                 data-action="like"
                 data-size="large"
                 data-show-faces="false"
                 data-share="false"
                 style=""></div>
            <div class="fb-like"
                 data-href="https://www.facebook.com/iPastBookBot"
                 data-layout="button_count"
                 data-action="like"
                 data-size="large"
                 data-show-faces="false"
                 data-share="false"
                 v-else></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-body container">
      <div class="text-left text-mini">
        <p class="text-white text-small">
          <b>{{$t('home.footerSource')}}</b>
          <select class="selector" id="area" :value="locale" @change="handleLocale">
            <option v-for="(item, index) in countries" :key="index"
                    :value="item.alias">{{item.label}}({{item.currency}})
            </option>
          </select>
        </p>
        <p class="text-white text-small">
          <b>{{$t('home.footerLanguage')}}</b>
          <select class="selector" id="lang" :value="language" @change="handleLanguage">
            <option :value="i.code" v-for="i in languages" :key="i.code">{{i.title}}</option>
          </select>
        </p>
        <p class="footer-tip text-gray">
          {{$t('home.footerTip')}}
        </p>
        <p class="text-white" v-show="false">
          商務合作:
          <a href="mailto:james@ilifediary.com" class="text-white">
            james@ilifediary.com
          </a>
        </p>
        <p class="text-white" v-show="false">
          客戶服務:
          <a href="mailto:hi@ilifediary.com" class="text-white">
            hi@ilifediary.com
          </a>
        </p>
      </div>
      <div class="text-center text-small sites-map" v-if="false">
        <a href="/">隱私條款</a>
        <a href="/">使用條款</a>
        <a href="/">材質價格</a>
        <a href="/">關於我們</a>
      </div>
      <div class="method-support">
        <img src="../../../static/images/home/visa-curved-128px.png" alt="">
        <img src="../../../static/images/home/mastercard-curved-128px.png" alt="">
        <img src="../../../static/images/home/american-express-curved-128px.png" alt="">
      </div>
      <div class="copyright">
        Copyright ©{{new Date().getFullYear()}} 杭州心书网络科技有限公司 All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import 'src/styles/layout/Footer.scss'
import countries from '../../../static/json/country.json'

export default {
  name: 'home-footer',
  data () {
    return {
      countries: countries.filter(i => ['US', 'TW', 'HK', 'MO'].includes(i.alias))
    }
  },
  computed: {
    ...mapGetters([
      'locale', 'language', 'user', 'messages'
    ]),
    languages () {
      return Object.keys(this.messages).map(k => {
        return {
          code: k,
          title: this.messages[k]?.language || k
        }
      })
    },
    pageLink () {
      return 'https://www.facebook.com/iPastBookBot/'
    }
  },
  methods: {
    ...mapActions([
      'setLocale', 'setLanguage'
    ]),
    handleLanguage (e) {
      const language = e.target.value
      this.setLanguage(language)
      window.location.reload()
    },
    handleLocale (e) {
      const locale = e.target.value
      this.setLocale(locale)
      window.location.reload()
    }
  }
}
</script>
